.layout-service {
  /* padding: 0 auto; */
  display: flex;
  /* background-color: var(--off-white); */
  justify-content: center;
  background-color: var(--primary-color);
}
.service-container {
  padding: 2rem 0;
  overflow: hidden;
}
.service-header {
  color: var(--white-color);
  text-align: center;
  margin: 2rem 0;
}
.service-header > h2 {
  font-size: 2rem;
}
.service-header > p {
  margin: 1rem 0;
  font-size: 1.2rem;
}
.service-cards-container {
  width: 72rem;
  /* background-color: pink; */
  margin: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.service-card {
  /* background-color: aqua; */
  background-color: var(--off-white);
  width: 22rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
}

.service-card::before {
  content: "";
  position: absolute;
  right: -15px;
  top: -15px;
  background: #e0e0e0;
  border-radius: 1rem;
  width: 100%;
  height: 0.65rem;
  z-index: -1;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}
.service-card:hover::before {
  transition-delay: 0.2s;
  transform: scale(40);
}

.service-card-header-center {
  display: flex;
  justify-content: center;
}

.service-card-heading {
  background-color: #f5f1fd;
  width: 6rem;
  height: 6rem;
  padding: 0.1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-card-heading > img {
  width: 4rem;
}
.service-card-info > h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0;
}
.service-card-info > p {
  color: #969696;
}

/* For extremely small screen devices (300px and below) */
@media only screen and (max-width: 299px) {
  .service-cards-container {
    width: 100%;
    justify-content: center;
    padding: 2rem 0;
  }
  .service-card {
    background-color: var(--off-white);
    width: auto;
    padding: 2rem;
    margin: 0 1rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1);
  }
  .service-header > h2 {
    font-size: 1.5rem;
  }

  .service-card-heading > img {
    width: 4rem;
  }
  .service-card-info > h3 {
    font-size: 1.2rem;
    margin: 1.5rem 0;
  }
  .service-card-info > p {
    font-size: 1rem;
    color: #969696;
  }
}

/* For extremely small screen devices (between 300px and 599px) */
@media (min-width: 300px) and (max-width: 599px) {
  .service-cards-container {
    width: 100%;
    justify-content: center;
    padding: 2rem 0;
  }
  .service-header > h2 {
    font-size: 1.5rem;
  }
  .service-card-heading > img {
    width: 4rem;
  }
  .service-card-info > h3 {
    font-size: 1.2rem;
    margin: 1.5rem 0;
  }
  .service-card-info > p {
    font-size: 1rem;
    color: #969696;
  }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
  .service-cards-container {
    width: 100%;
    justify-content: center;
    padding: 2rem 0;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .service-cards-container {
    width: 47rem;
    justify-content: center;
    padding: 2rem 0;
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  .service-cards-container {
    width: 52rem;
    justify-content: center;
    padding: 2rem 0;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  .service-cards-container {
    width: 72rem;
    padding: 2rem 0;
  }
}