.pre-launch-container{
    height: 100vh;
    width: 100vw;
text-align: center;
/* background-color: aqua; */
}

.pre-launch-container > img{
 
 height: 50%;
 object-fit: cover;

position: absolute;
left: 40vw;
top: 20vh;
    
}