/* .layout-hero { */
/* padding: 0 auto; */
/* display: flex; */
/* justify-content: center; */
/* } */
.header-container {
  max-width: 1200px;
  flex-wrap: wrap;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  /* background-color: var(--white-color); */
  /* background-color: yellow; */
}

.main-container {
  /* background-color: green; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  /* width: 80%; */
}

.main-character > img {
  width: 25rem;
  margin: 0;
}

.info-container {
  /* background-color: burlywood; */
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.info-title {
  font-size: 2.4rem;
  color: var(--text-color);
}
.info-para {
  font-size: 1.2rem;
  color: #4e4e4e;
}

.header-input-field {
  background-color: var(--white-color);
  width: 25rem;
  padding: 0.6rem;
  border-radius: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ccc;
}
.header-input-field > form > input {
  font-size: 1rem;
  width: 15rem;
  border: none;
  outline: none;
  margin-left: 0.5rem;
}

.get-started-btn {
  background-color: var(--primary-color);
  border: none;
  width: 8rem;
  font-size: 1rem;
  padding: 0.6rem;
  font-weight: bold;
  border-radius: 10rem;
  cursor: pointer;
  color: var(--white-color);
}

.header-send-btn {
  display: none;
  border: none;
  border-radius: 100%;
  padding: 0.5rem;
  color: var(--white-color);
  background-color: var(--primary-color);
}

/* For extremely small screen devices (between 300px and 599px) */
@media only screen and (max-width: 599px) {
  .header-container {
    width: auto;
    padding: 4rem 1.2rem;
    /* background-color: aquamarine; */
  }
  .main-container {
    flex-wrap: wrap;
    width: auto;
    /* background-color: blueviolet; */
  }

  .info-container {
    margin: 0;
    padding: 0;
    margin-bottom: 1.5rem;
    width: auto;
  }
  .info-title {
    width: auto;
    font-size: 1.8rem;
    color: var(--text-color);
  }
  .info-para {
    margin: 0;
    padding: 0;
    width: auto;
    font-size: 1rem;
  }
  .main-character > img {
    width: 90%;
    margin: 0 1rem;
  }

  .header-input-field {
    width: 18rem;
    padding: 0.6rem;
  }

  .header-input-field > form > input {
    font-size: 1rem;
    width: 12rem;
    border: none;
    outline: none;
    margin-left: 0.5rem;
  }

  .get-started-btn {
    display: none;
  }
  .header-send-btn {
    display: block;
  }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
  .header-container {
    /* padding: 4rem 5%; */
    width: auto;
    padding: 4rem 5%;
  }
  .main-container {
    flex-wrap: wrap;
  }

  .info-container {
    margin-bottom: 1.5rem;
    width: 90%;
  }
  .info-title {
    font-size: 2.4rem;
    color: var(--text-color);
  }
  .info-para {
    font-size: 1.2rem;
  }
  .main-character > img {
    width: 100%;
    margin: 0 1rem;
  }
  .header-input-field {
    width: 25rem;
    padding: 0.6rem;
  }

  .header-input-field > form > input {
    font-size: 1rem;
    width: 15rem;
    border: none;
    outline: none;
    margin-left: 0.5rem;
  }

  .get-started-btn {
    width: 8rem;
    font-size: 1rem;
    padding: 0.6rem;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .header-container {
    /* padding: 4rem 5%; */
    width: 90%;
    /* padding: 4rem 5%; */
    /* background-color: aqua; */
    margin: 0;
  }
  .main-container {
    flex-wrap: wrap;
    width: 100%;
  }

  .info-container {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  .info-title {
    font-size: 2.4rem;
    color: var(--text-color);
  }
  .info-para {
    font-size: 1.2rem;
  }
  .main-character > img {
    width: 80%;
    margin: 0 1rem;
  }
  .header-input-field {
    width: 25rem;
    padding: 0.6rem;
  }

  .header-input-field > form > input {
    font-size: 1rem;
    width: 15rem;
    border: none;
    outline: none;
    margin-left: 0.5rem;
  }

  .get-started-btn {
    width: 8rem;
    font-size: 1rem;
    padding: 0.6rem;
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  .header-container {
    /* padding: 5rem 15%; */
    width: auto;
    padding: 4rem 0.5rem;
    margin: 0;
    /* background-color: aqua; */
    justify-content: center;
  }

  .main-container {
    flex-wrap: nowrap;
    width: 80%;
    /* background-color: chartreuse; */
  }

  .info-container {
    width: 40vw;
  }
  .info-title {
    font-size: 2rem;
    color: var(--text-color);
  }
  .info-para {
    font-size: 1rem;
  }
  .main-character > img {
    width: 40vw;
    margin: 0 0 0 1rem;
  }

  .header-input-field {
    width: 22rem;
    padding: 0.6rem;
  }

  .header-input-field > form > input {
    font-size: 1rem;
    width: 12rem;
    border: none;
    outline: none;
    margin-left: 0.5rem;
  }

  .get-started-btn {
    width: 8rem;
    font-size: 1rem;
    padding: 0.6rem;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  .header-container {
    width: auto;
    padding: 4rem 0.5rem;
    display: flex;
    margin: auto;
    /* background-color: aqua; */
  }
  .main-container {
    flex-wrap: nowrap;
    /* background-color: yellow; */
    width: 100%;
  }
  .info-container {
    width: 40rem;
  }
  .info-title {
    font-size: 2.4rem;
    color: var(--text-color);
  }
  .info-para {
    width: 30rem;
    font-size: 1rem;
  }
  .main-character > img {
    width: 30rem;
    margin: 0 0 0 1rem;
  }
}
