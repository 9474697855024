.layout-nav {
  /* display: flex; */
  background-color: var(--off-white);
  /* background-color: yellow; */
  /* justify-content: center; */
}
.navbar-container {
  /* background-color: aqua; */
  font-size: 1rem;
  max-width: 1200px;
  padding: 1rem 0.5rem;
  color: var(--primary-text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  top: 0;
  z-index: 10;
  margin: auto;
}

.navbar-logo img {
  width: 10rem;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar li {
  margin: 0 1rem;
}

.navbar li {
  position: relative;
  text-decoration: none;
}
.navbar a {
  color: var(--primary-text-color);
}
.navbar a:hover {
  color: var(--primary-text-color);
}
.navbar li:before {
  content: "";
  position: absolute;
  bottom: -2px;
  height: 2px;
  width: 0;
  background: var(--primary-color);
  border-radius: 50px;
  transition: width 0.3s ease;
}

.navbar li:hover:before {
  width: 100%;
}

.navbar-btn {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 0.8rem;
  border-radius: 0.2rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}

.navbar-btn:hover {
  cursor: pointer;
}

.menu-btn {
  padding: 5px;
  background-color: transparent;
  color: var(--black-color);
  font-size: 1.8rem;
  border: none;
  outline: none;
  cursor: pointer;
  display: none;
}

/* Medium screen devices (768px and below)  */
@media only screen and (max-width: 1049px) {
  .navbar-container {
    /* background-color: pink; */
    font-size: 1rem;
    width: 90%;
  }

  .navbar-logo img {
    width: 10rem;
  }

  .menu-btn {
    display: block;
    font-size: 1.8rem;
  }

  .navbar-btn {
    display: none;
  }

  .navbar {
    padding-top: 8rem;
    position: fixed;
    top: 0;
    right: -100vh;
    height: 100%;
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    background-color: var(--primary-color);
    transition: 1s;
    z-index: 10;
  }

  .responsive_nav {
    transform: translateX(-100vh);
  }

  .menu-close-btn {
    color: var(--white-color);
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .navbar a {
    color: var(--white-color);
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 1050px) {
  .navbar-container {
    width: 80%;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {

   
    .navbar-container{
        font-size: 1rem;
        width: 72rem;
        padding: 1rem 0.5rem;
     
    
        color: var(--text-color);
    
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* flex-wrap: wrap; */
        overflow: hidden;
        /* background-color: green; */
        top: 0;
        z-index: 10;
    }
    
    .navbar-logo img{
        width: 10rem;
        margin-top: 0.5rem;
    }
    
    .navbar{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .navbar li{
        margin: 0 1rem;
    }
    
    a{
        color: var(--black-color);
        transition: 0.3s ease-in-out;
    }
    
    a:hover{
        color: var(--primary-color);
    }
    
    .navbar-btn{
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.5rem 0.8rem;
        border-radius: 9px;
        background-color: var(--primary-color);
        color: var(--white-color);
        border: none;
        margin-right: 3rem;
    }
    
    .navbar-btn:hover{
        cursor: pointer;
    }
    
    
    .menu-btn{
        padding: 5px;
        background-color: transparent;
        color: var(--black-color);
        font-size: 1.8rem;
        border: none;
        outline: none;
        cursor: pointer;
        display: none;
    }
   
    
}
