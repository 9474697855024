.footer-container{
    /* width: 90%; */
    background-color: rgb(238, 238, 238);
    padding: 0.5rem 18%;
    color: var(--primary-text-color);
    
}
.footer-part1{
    /* background-color: lightblue; */
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-logo{
    display: flex;
    align-items: center;
    gap: 1rem;
    /* background-color: aqua; */
}
.footer-logo img{
    width: 10rem; 
}
.footer-logo {
    font-size: 1.6rem;
    color: var(--primary-text-color);
}
.footer-link nav>ul {
    /* background-color: antiquewhite; */
    display: flex;
    gap: 3rem;
}
.footer-link a{
    color: var(--primary-text-color);
}
.footer-link a:hover{
    color: var(--primary-color);
}

.footer-part2{
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-social-media-icon a{
    margin-left: 1rem;
    color: var(--primary-text-color);
}

.footer-part3{
    text-align: center;
    /* background-color: antiquewhite; */
    margin-top:1rem;
}
.footer-email{
    display: none;
}
.brake-line{
    display: none;
}





/* For extremely small screen devices (595px and below)  */
@media only screen and (max-width: 595px) {
    .footer-container{
        /* width: 90%; */
        background-color: rgb(238, 238, 238);
        padding: 0.5rem 6%;
        color: var(--primary-text-color);
       
    }
    .footer-link nav>ul {
        /* background-color: antiquewhite; */
        display: none;
        gap: 1rem;
    }
    .footer-link a{
        font-size: 0.85rem;
    }
    .footer-logo img{
        width: 8rem; 
    }
}

 /* Small screen devices (600px and above)  */
@media only screen and (min-width: 600px) {
    .footer-container{
        /* width: 90%; */
        background-color: rgb(238, 238, 238);
        padding: 0.5rem 6%;
        color: var(--primary-text-color);
    }
    .footer-link nav>ul {
        /* background-color: antiquewhite; */
        display: flex;
        gap: 1rem;
    }
    .footer-link a{
        font-size: 0.85rem;
    }
    .footer-logo img{
        width: 8rem; 
    }
}

 /* Medium screen devices (768px and above)  */
@media only screen and (min-width: 768px) {
    .footer-container{
        /* width: 90%; */
        background-color: rgb(238, 238, 238);
        padding: 0.5rem 6%;
        color: var(--primary-text-color);
    }
    .footer-logo img{
        width: 8rem; 
    }
    .footer-link nav>ul {
        /* background-color: antiquewhite; */
     
        display: flex;
        gap: 1rem;
    }
    .footer-link a{
        font-size: 1rem;
    }
}

 /* Big screen devices (889px and above)  */
@media only screen and (min-width: 889px) {
    .footer-container{
        /* width: 90%; */
        background-color: rgb(238, 238, 238);
        padding: 0.5rem 6%;
        color: var(--primary-text-color);
    }

}

 /* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
    .footer-container{
        /* width: 90%; */
     
        background-color: rgb(238, 238, 238);
        padding: 0.5rem 18%;
        color: var(--primary-text-color);
    }
    .footer-part1{
        /* background-color: lightblue; */
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-logo{
        display: flex;
        align-items: center;
        gap: 1rem;
        /* background-color: aqua; */
    }
    .footer-logo img{
        width: 10rem; 
    }
    .footer-logo {
        font-size: 1.6rem;
        color: var(--primary-text-color);
    }
    .footer-link nav>ul {
        /* background-color: antiquewhite; */
        display: flex;
        gap: 3rem;
    }
    .footer-link a{
        color: var(--primary-text-color);
    }
    .footer-link a:hover{
        color: var(--primary-color);
    }
    
    .footer-part2{
        /* background-color: aqua; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-social-media-icon a{
        margin-left: 1rem;
        color: var(--primary-text-color);
    }
    
    .footer-part3{
        text-align: center;
        /* background-color: antiquewhite; */
        margin-top:1rem;
    }
    .footer-email{
        display: none;
    }
    .brake-line{
        display: none;
    }
    
}