.testimonial-container {
  background-color: var(--off-white);
  /* background-color: navajowhite; */
  /* padding: 10rem 10rem 10rem 12rem; */
  padding: 3rem 0;
  flex-wrap: wrap;
  overflow: hidden;
}
.testimonial-heading {
  color: var(--primary-text-color);
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonial-heading-title {
  color: var(--primary-color);
  /* font-size: 2rem; */
}
.testimonial-heading h2 {
  /* font-size: 2rem; */
}
.testimonial-cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.testimonial-card {
  border-radius: 1rem;
  box-shadow: 0 0 4px #ccc;
  border: 1px solid rgb(223, 223, 223);
  padding: 2rem;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30rem;
  background-color: var(--white-color);
  position: relative;
}
.testimonial-card-header {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
}
.testimonial-card-heading {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 10rem;
  position: relative;
}

.right-upward-arrow {
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
}
.testimonial-card-heading > a {
  /* font-size: 1.3rem; */
  text-decoration: none;
  color: white;
}
.quote-icon1 {
  color: var(--primary-color);
}
.quote-icon2 {
  /* display: ; */
  color: var(--primary-color);
}
.star-icon {
  color: yellow;
}
.testimonial-footer {
  /* background-color: rebeccapurple; */
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testimonial-footer-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.testimonial-footer-left > img {
  width: 4rem;
  border-radius: 50%;
}
/* .testimonial-rating{
    display: flex;
    align-items: center;
}
.testimonial-rating > p{
    margin: 0 0.5rem;
} */

/* Media Query for Mobile */
@media (max-width: 565px) {
  .testimonial-container {
    width: 100%;
    padding: 4rem 1rem;
  }
  .testimonial-heading-title {
    text-align: center;
    /* font-size: 2rem; */
  }
  .testimonial-heading > h2 {
    /* font-size: 2rem; */
    /* background-color: blue; */
    text-align: center;
    width: 100%;
  }
  .testimonial-card {
    width: 100%;
    min-height: auto;
  }
  .testimonial-footer-left > img {
    width: 3rem;
    border-radius: 50%;
  }
  .testimonial-footer-info > h3 {
    font-size: 1rem;
  }
  .testimonial-footer-info > P {
    font-size: 0.8rem;
  }
}

@media (min-width: 912px) {
  .testimonial-container {
    width: 100%;
  }

  .testimonial-heading-title {
    text-align: center;
    font-size: 2rem;
  }
  .testimonial-heading h2 {
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }
}

@media (min-width: 750px) {
  .testimonial-container {
    padding: 3rem 1rem;
  }
  .testimonial-cards-container {
    display: flex;
    flex-wrap: nowrap;
  }
  .testimonial-card {
    width: 23rem;
  }
}
