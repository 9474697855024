.why-choose-us {
  background-color: var(--off-white);
  text-align: center;
  padding: 40px 0;
}

.why-choose-us h2 {
  font-size: 24px;
  margin-bottom: 2rem;
}

/* Styling for the card container */
.card-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

/* Styling for individual cards */
.card {
  border-radius: 8px;
  padding: 20px;
  margin: 0 10px;
  max-width: 300px;
  text-align: center;
  height: 200px;
}

.card h3 {
  font-size: 18px;
  margin-bottom: 1rem;
}

.card h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.card p {
  font-size: 14px;
}


/* For extremely small screen devices (595px and below)  */
@media only screen and (max-width: 595px) {
  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0rem;
  }

  .card h2 {
    /* font-size: 3rem; */
    margin-bottom: 0.2rem;
  }
}

/* Small screen devices (600px and above)  */
@media only screen and (min-width: 600px) {
  .card-container {
    gap: 2rem;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .card-container {
    gap: 2rem;
  }
}

/* Big screen devices (889px and above)  */
@media only screen and (min-width: 889px) {
  .card-container {
    gap: 5rem;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  .card-container {
    gap: 5rem;
  }

}