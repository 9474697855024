
.clientcontainer {
  /* max-width: %; */
  margin: 0 auto;
  /* padding: 0 ; */
  /* padding: 20px; */
  /* margin-bottom: 10rem; */

  padding: 2rem 0;

  /* height: 50vh; */
 
  background-color: var(--off-white);
  /* background-color: aqua; */
}

.clientheading {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: Black;
}

.client-subheading {
  text-align: center;
  margin-bottom: 30px;
}

.client-subheading p {
  font-size: 16px;
}

.clients-logo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-bottom: 30px; */

}

.clients-logo > img{
  width: 16rem;
  /* height: 3rem; */
  /* max-width: 10rem; */
  margin: 2rem 3rem;
}




/* CSS for responsiveness */


@media screen and (max-width: 480px) {


  .client-subheading{
    margin: 0 1rem;
    
  }

  .clients-logo{
    justify-content: center;
  }

.clients-logo > img{
  align-self: center;
  /* height: 2rem; */
  object-fit: cover;
  margin: 2rem 1rem;
  /* width: 35%; */
}

}




