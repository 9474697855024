@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;700;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    font-family: 'Inter', sans-serif;
}
html,body{
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

:root{
    /* website scheme colors */
    --primary-color:#0062d1;
    --white-color:#ffffff;
    --black-color:#000000;
    --off-white:#f9fafb;
    --footer-line:#8D8D8D;
    --card-bg:#FCFCFC;
    /* font colors */
    --primary-text-color:#1a1a1a;
    --secondary-text-color:#2a2a2a;
}

