.contact-main-container{
  padding: 5rem 0 5rem 0;
  background-color: var(--off-white);

}

.container {
  background: white;
  /* padding: 10rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* position: relative; */
  /* min-width: 1100px; */
  /* min-height: 550px; */
  margin: 0 20% 2rem 20%;
  border-radius: 15px;
  box-shadow: 5px 5px 25px 1px rgba(0,0,0,0.15);
}

.contactInfo {
  /* position: absolute; */
  /* width: 450px;
  height: 100%; */
  /* height: 100%; */
  background: #000;
  width: 30vw;
  padding: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  justify-content: space-between;
}

.contactInfo {
  color: #fff;
}

 .contactInfo ul.info {
  margin: 20px 0;
}

 .contactInfo ul.info li {
  list-style: none;
  display: flex;
  margin: 20px  0;
  cursor: pointer;
  align-items: flex-start;
}

.contactInfo ul.info li span {
  margin-right: 15px;
}

 .contactInfo ul.social-media {
  color: #ffffff;
  display: flex;
}
 .contactInfo ul.social-media li {
  list-style: none;
  margin-right: 15px;
}

 .contactInfo ul.social-media li a {
  text-decoration: none;
}

 .contactInfo ul.social-media li a i {
  filter: invert(1);
}
 .contactInfo ul.social-media li a i:hover {
  filter: invert(0);
  color: rgb(50, 149, 255);
}

.contactForm {
  width: 70vw;
  padding: 3rem;
  /* background: #fff; */

  /* background: green; */
  /* padding-left: 250px; */
  /* margin-left: 250px; */

  /* height: 100%; */
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

 .formBox {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

 .inputBox {
  /* position: relative; */
  margin-bottom: 35px;
}

   .inputBox.w50 {
  width: 47%;
}

  .inputBox.w100 {
  width: 100%;
}

   .inputBox input,
 .inputBox textarea {
  width: 100%;
  resize: none;
  padding: 5px 0;
  border: none;
  outline: none;
  border-bottom: 1px solid #777;
}

 .inputBox textarea {
  height: 100px;
}

 .button button {
  /* position: relative; */
  color: #fff;
  background: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

 .contactForm .formBox .button button:hover {
  background: #0a0a0a;
}


.custom-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #bbf7d0;
  color: #15803d;
  padding: 0.75rem 1rem;
  border: 1px solid #4ade80;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  
}

.cross-icon{
  color: #22c55e;
  cursor: pointer;
}

@media (max-width : 1250px) {
  .container {
    margin: 2rem 5%;
  }
} 

@media (max-width : 450px) {
  .container {
    flex-direction: column;
    margin: 2rem 5%;
  }
  .contactForm, .contactInfo{
    width: auto;
  }
} 
@media (max-width : 750px) {
  .container {
    flex-direction: column;
    margin: 2rem 5%;
  }
  .contactForm, .contactInfo{
    width: auto;
  }
} 


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  
  margin: 0;
   
}



input[type=number].number-to-text {
    -moz-appearance: textfield;
}