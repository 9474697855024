.tech-stack-container {
  width: 100%;
  /* height: 50vh; */
  /* background-color:green; */
  margin-bottom: 8rem;
  /* background-color: #fff; */
  /* background-color: blueviolet; */
}

.tech-info-container {
  /* width:90%; */
  /* background-color: antiquewhite; */
  padding: 4rem 0 0 0;
  margin: 0 18%;
  /* margin-top: 1.5rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: aqua; */
  /* padding: 1.5rem 0; */
  margin-bottom: 3rem;
}

.tech-info-title {
  /* background-color: pink; */
  font-size: 2rem;
  font-weight: bold;
  width: 40%;
}
.tech-info-desc {
  font-size: 1rem;
  overflow: hidden;
  width: 40%;
  text-align: center;
}

.grid-container-1 {
  display: grid;
  justify-content: center;
  grid-template-columns: 15% 15% 15% 15%;
  gap: 0rem;
  /* background-color: #2196F3; */
  /* padding: 1rem; */
}

.grid-container-2 {
  display: grid;
  justify-content: center;
  grid-template-columns: 15% 15% 15% 15% 15%;
  gap: 0rem;
  /* background-color: #2196F3; */
  /* padding: 1rem; */
}

.grid-container img {
  object-fit: cover;
  /* width: 100%; */
  height: 4rem;
  /* display: none; */
}

.grid-container-3 {
  display: grid;
  justify-content: center;
  grid-template-columns: 15% 15% 15% 15%;
  gap: 0rem;
  /* background-color: #2196F3; */
  /* padding: 1rem; */
}

.grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: center;
  border-right: 0.5px solid grey;
  border-bottom: 0.5px solid grey;

  padding: 3rem 0;

  max-height: 10rem;
}
.grid-container > div:last-child {
  border-right: none;
}
.wix-image img {
  height: 2.5rem;
}

@media (min-width: 451px) and (max-width: 1080px) {
  .tech-info-container {
    margin: 0 5%;
    margin-top: 5rem;
  }

  .tech-info-title {
    font-size: 1.6rem;
    width: 40%;
  }
  .tech-info-desc {
    font-size: 1rem;
    width: 40%;
  }

  .grid-container-1 {
    grid-template-columns: 20% 20% 20% 20%;
  }
  .grid-container-2 {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
  .grid-container-3 {
    grid-template-columns: 20% 20% 20% 20%;
  }

  .grid-container > div {
    height: 2rem;
  }

  .grid-container img {
    height: 2.8rem;
  }
}

@media (max-width: 450px) {
  .tech-stack-container {
    margin-bottom: 5rem;
  }
  .tech-info-container {
    margin: 0 1rem;
    margin-top: 1rem;
    flex-direction: column;
  }

  .tech-info-title {
    text-align: center;
    font-size: 1.6rem;
    width: 100%;
    margin-bottom: 1rem;
  }
  .tech-info-desc {
    text-align: center;
    font-size: 1rem;
    width: 100%;

    margin-bottom: 1rem;
  }

  .grid-container-1 {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .grid-container-2 {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
  .grid-container-3 {
    grid-template-columns: 25% 25% 25% 25%;
  }
  .grid-container > div {
    padding: 2rem 0;
  }
  .grid-container > div {
    height: 1.2rem;
  }

  .grid-container img {
    height: 1.8rem;
  }
  .wix-image img {
    height: 1.2rem;
  }
}
